import React, { useState, useCallback, useEffect } from 'react';
import { IDocument } from 'src/store/services/tagsApi/tagTypes';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconDelete } from 'src/assets/footer-menu/IconDelete.svg';
import ImageScanner from 'src/components/ImageScanner';

interface ImagePreviewerProps {
    assetId: number; 
    capturedImages: IDocument[];
    setCapturedImages: React.Dispatch<React.SetStateAction<IDocument[]>>;
    dataDocuments?: IDocument[];
    isBatchMode?: boolean;
}

const ImagePreviewer: React.FC<ImagePreviewerProps> = ({
    assetId,
    capturedImages,
    setCapturedImages,
    dataDocuments = [],
    isBatchMode = false,  
}) => {
    const [isImageScannerOpen, setIsImageScannerOpen] = useState<boolean>(false);
    const { t } = useTranslation('tag');

    useEffect(() => {
        const fetchData = async () => {
            if (!assetId) return;
            
            try {
                if (isBatchMode) {
                    const batchResponse = await fetch(`/assets/v1/batch/${assetId}`);
                    if (!batchResponse.ok) {
                        console.warn(`Failed to fetch batch data: ${batchResponse.status}`);
                        console.warn('Response text:', await batchResponse.text()); // Log response text
                        return;
                    }
                    
                    try {
                        const batchData = await batchResponse.json();
                        const allImages: IDocument[] = [];
                        
                        for (const asset of batchData.assets || []) {
                            const assetResponse = await fetch(`/assets/v1/asset/${asset.id}`);
                            if (!assetResponse.ok) {
                                console.warn(`Failed to fetch asset ${asset.id}: ${assetResponse.status}`);
                                console.warn('Response text:', await assetResponse.text()); // Log response text
                                continue;
                            }
                            
                            const assetData = await assetResponse.json();
                            const assetImages = assetData.documents.filter(
                                (doc: IDocument) => doc.documentType === 'IMAGE'
                            );
                            allImages.push(...assetImages);
                        }
                        setCapturedImages(allImages);
                    } catch (error) {
                        console.warn('Error parsing batch data:', error);
                    }
                } else {
                    const response = await fetch(`/assets/v1/asset/${assetId}`);
                    if (!response.ok) {
                        console.warn(`Failed to fetch asset data: ${response.status}`);
                        console.warn('Response text:', await response.text()); // Log response text
                        return;
                    }
                    
                    try {
                        const data = await response.json();
                        const tagImages = data.documents.filter(
                            (doc: IDocument) => doc.documentType === 'IMAGE'
                        );
                        setCapturedImages(tagImages);
                    } catch (error) {
                        console.warn('Error parsing asset data:', error);
                    }
                }
            } catch (error) {
                console.warn('Network error:', error);
            }
        };
    
        fetchData();
    }, [assetId, setCapturedImages, isBatchMode]);

    const handleDeleteImage = (index: number) => {
        setCapturedImages(prevState => {
            const updatedImages = prevState.filter((_, i) => i !== index);
            console.log('Updated images:', updatedImages);
            return updatedImages;
        });
    };

    const handleCloseCreatingModal = useCallback(() => {
        setIsImageScannerOpen(false);
    }, []);

    const addLocalImage = useCallback(
        (img: string) => {
            setCapturedImages(prevState => [
                ...prevState,
                { url: img, documentType: 'IMAGE' },
            ]);
        },
        [setCapturedImages]
    );

    const moveImage = (index: number, direction: 'left' | 'right') => {
        setCapturedImages(prevState => {
            const newImages = [...prevState];
            const imagesOnly = newImages.filter(doc => doc.documentType === 'IMAGE');
            const nonImages = newImages.filter(doc => doc.documentType !== 'IMAGE');
            
            const imageIndices = newImages
                .map((doc, i) => doc.documentType === 'IMAGE' ? i : -1)
                .filter(i => i !== -1);
                
            const currentFullIndex = imageIndices[index];
            const targetFullIndex = imageIndices[direction === 'left' ? index - 1 : index + 1];
            
            [newImages[currentFullIndex], newImages[targetFullIndex]] = 
            [newImages[targetFullIndex], newImages[currentFullIndex]];
            
            return newImages;
        });
    };

    return (
        <div>
            <label className="block text-gray-500 text-sm font-medium mb-0 pl-3 -mb-4">
                {t('asset-images-label')}
            </label>
            <div className="mt-4 p-4 border border-gray-300 rounded-lg">
                <div className="flex gap-8 flex-wrap justify-center">
                    {capturedImages.map((img, index) => (
                        <div key={index} className="relative">
                            <img
                                src={img.url}
                                alt={`Asset image ${index + 1}`} 
                                className="w-32 h-32 object-cover border-2 rounded cursor-pointer border-gray-300"
                            />
                            <span className="absolute top-1 left-1 bg-white text-black font-bold px-1 rounded">
                                {index + 1}
                            </span>
                            <div className="flex justify-between mt-2">
                                <button
                                    type="button"
                                    className="bg-gray-300 p-1 rounded disabled:opacity-50"
                                    onClick={() => moveImage(index, 'left')}
                                    disabled={index === 0}
                                    aria-label={`Move image ${index + 1} left`}
                                >
                                    ←
                                </button>
                                <button
                                    type="button"
                                    className="bg-gray-300 p-1 rounded disabled:opacity-50"
                                    onClick={() => moveImage(index, 'right')}
                                    disabled={index === capturedImages.length - 1}
                                    aria-label={`Move image ${index + 1} right`}
                                >
                                    →
                                </button>
                            </div>
                            <div className="flex justify-center mt-2">
                                <button
                                    type="button"
                                    className="flex items-center gap-1 bg-red-500 text-white p-1 rounded"
                                    onClick={() => handleDeleteImage(index)} 
                                    aria-label={`Delete image ${index + 1}`}
                                >
                                    <IconDelete className="w-4 h-4 fill-white" />
                                    <span className="text-sm">{t('Delete')}</span>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {isImageScannerOpen && (
                <ImageScanner
                    onClose={handleCloseCreatingModal}
                    addLocalImage={addLocalImage}
                    assetId={assetId}
                />
            )}
        </div>
    );
};

export default ImagePreviewer;
