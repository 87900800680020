import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import { usePostAssetByIdMutation } from '../store/services/tagsApi';
import { usePostBatchDocumentMutation } from '../store/services/batchApi';
import { useTranslation } from 'react-i18next';
import { createFormDataFile } from '../helpers/createFormDataFile';

interface IImageScannerProps {
  onClose: () => void;
  addLocalImage?: (img: string) => void;
  assetId: number;
  isBatchMode?: boolean;
}

const ImageScanner = ({ onClose, addLocalImage, assetId, isBatchMode = false }: IImageScannerProps) => {
  const { t } = useTranslation('tag');
  const [postAssetById] = usePostAssetByIdMutation();
  const [postBatchById] = usePostBatchDocumentMutation();
  const webcamRef = useRef<any>(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    addLocalImage?.(imageSrc);
    const onLoadFile = (formData: FormData) => {
      if (isBatchMode) {
        postBatchById({ batchId: assetId, body: formData });
      } else {
        postAssetById({ assetId, body: formData });
      }
    };

    createFormDataFile({
      documentType: 'IMAGE',
      imageScannerSrc: imageSrc,
      onLoadFile,
      addLocalImage,
    });
    onClose();
  };

  return (
    <div className="flex flex-col justify-center items-center h-full p-6 bg-gray-100">
      <div className="flex justify-center mb-4 w-full flex-grow">
        <div
          className="resizable border-4 border-blue-500 rounded-lg shadow-lg overflow-hidden"
          style={{ resize: 'both', overflow: 'auto', minWidth: '300px', minHeight: '225px' }} 
        >
          <Webcam
            audio={false}
            ref={webcamRef}
            videoConstraints={{
              facingMode: 'environment',
              aspectRatio: 3 / 4, 
              width: 1280, 
              height: 960,  
            }}
            screenshotFormat="image/webp"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <div className="flex justify-center items-center w-full">
        <button
          type="button"
          onClick={capture}
          className="focus:outline-none text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2 transition duration-200 ease-in-out"
        >
          {t('take_photo')}
        </button>
      </div>
    </div>
  );
};

export default ImageScanner;
