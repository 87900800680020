import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps, useFormik } from 'formik';
import { PatchBatchBodyData } from 'src/store/services/batchApi/batchTypes';
import { ServerError } from 'src/store/storeTypes';
import { getBatchInitialValues, IBatchValues } from './getBatchInitialValues';
import { getXAuthOwnerHeader } from 'src/helpers/getXAuthOwnerHeader';
import { usePostAssetByIdMutation } from 'src/store/services/tagsApi';
import { usePostBatchDocumentMutation } from 'src/store/services/batchApi';
import FormField from 'src/components/FormField';
import { BatchValidation } from 'src/components/Modals/Batch/components/BatchForm/batchValidation';
import OrganizationsSelect from 'src/components/OrganizationsSelect';
import SaveButton from 'src/components/SaveButton';
import CloseButton from 'src/components/CloseButton';
import ModalTitle from 'src/components/Modals/components/ModalTitle';
import FormWrapper from 'src/components/FormWrapper';
import ServerFormError from 'src/components/ServerFormError';
import ModalContainer from 'src/components/Modals/components/ModalContainer';
import BatchTypeSelect from './components/BatchTypeSelect';
import BatchTagsMultiselect from './components/BatchTagsMultiselect';
import TagStateSelect from '../../../Tag/components/TagForm/components/TagStateSelect';
import LocationSelect from '../../../Tag/components/TagForm/components/LocationSelect';
import CategoriesSelect from '../../../Tag/components/TagForm/components/CategoriesSelect';
import TakePhotoButton from 'src/components/Modals/Tag/components/TagForm/components/TakePhotoButton';
import UploadDocumentsButton from 'src/components/Modals/Tag/components/TagForm/components/UploadDocumentButton';
import CustomMDEditor from 'src/components/MDEditor';
import CustomAccordion from 'src/components/CustomAccordion';

interface IBatchForm {
  editMode?: IBatchEditMode;
  title: string;
  onSubmit: any;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: any;
  isOpened: boolean;
  onClose: () => void;
  data?: PatchBatchBodyData;
  formType?: IFormType;
}

export type IFormType = 'fromNewObjects' | 'fromExistingObjects';
export type IBatchEditMode = 'default' | 'editBatchAssets' | 'editBatch' | '';

const BatchForm = ({
  editMode,
  onSubmit,
  isSuccess,
  isLoading,
  isError,
  error,
  title,
  data,
  isOpened,
  onClose,
  formType = 'fromNewObjects',
}: IBatchForm) => {
  const { t } = useTranslation('batches');
  const [activeTab, setActiveTab] = useState<IFormType>(formType);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const assetId = data?.id;
  const initialValues = getBatchInitialValues(data, editMode === 'editBatchAssets');
  const [postAssetById] = usePostAssetByIdMutation();
  const [isUploadAccordionOpen, setIsUploadAccordionOpen] = useState<boolean>(true);
  const [postBatchDocument] = usePostBatchDocumentMutation();

  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    errors,
    touched,
    submitForm,
  }: FormikProps<IBatchValues> = useFormik<IBatchValues>({
    onSubmit: (values) => {
      const payload: any = {
        batchId: data?.id,
        body: {
          ...(editMode !== 'editBatchAssets' && {
            name: values.name,
            description: values.description,
          }),
          ...(editMode === 'editBatchAssets' && {
            assetState: values.assetState || null,
            locationId: values.location?.id || null,
            categories: values.categories || null,
            description: values.assetsDescription || null,
          }),
          ...(activeTab === 'fromNewObjects' && !editMode && values.numberOfNewAssets
            ? { numberOfNewAssets: values.numberOfNewAssets }
            : {}),
          ...(activeTab === 'fromExistingObjects' && {
            assetIds: values.assetIds,
          }),
          ...(!editMode && {
            type: values.type,
          }),
        },
        ...getXAuthOwnerHeader(values.organization),
      };

      if (values.consecutiveAssetsCount && selectedTags.length === 1) {
        payload.body.consecutiveAssetIds = {
          assetId: selectedTags[0],
          count: values.consecutiveAssetsCount,
        };
      }

      onSubmit(payload);
    },
    initialValues,
    validationSchema: BatchValidation(editMode, activeTab),
  });

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <ModalContainer isModalOpened={isOpened} onClose={onClose} isFormModal={true}>
      <ModalTitle title={title} />
      <FormWrapper onSubmit={handleSubmit}>
        {editMode === 'editBatchAssets' && (
          <div className="flex justify-center font-mukta-mahee font-bold text-lg">
            {t('batch-assets-update-notification')}
          </div>
        )}

        {editMode !== 'editBatchAssets' && (
          <>
            <FormField
              value={values.name}
              name={'name'}
              placeholder={t('batch-name-holder')}
              onChange={handleChange}
              isError={!!errors.name && !!touched.name}
              errorMessage={(touched.name && errors?.name) || ''}
            />
            <div data-color-mode="light" className="markdown-editor mb-8">
              <CustomMDEditor
                id="batch-description-editor"
                label={t('batch-description-place-holder')}
                value={values.description || ''}
                onChange={(value) => setFieldValue('description', value)}
              />
            </div>
            {touched.description && errors.description && (
              <div className="error-message">{errors.description}</div>
            )}
          </>
        )}

        {editMode !== 'editBatchAssets' && editMode !== 'editBatch' && (
          <div className="text-lg font-medium text-center text-gray-500  border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <ul className="flex justify-center space-x-4 -mb-px">
              <li>
                <button
                  onClick={() => setActiveTab('fromNewObjects')}
                  className={`inline-block px-6 py-3 border-b-2 rounded-t-lg ${
                    activeTab === 'fromNewObjects'
                      ? 'text-blue-600 border-gray-400 border-b-blue-600 rounded-t-lg border'
                      : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                  }`}
                >
                  {t('batch-creation-new-text')}
                </button>
              </li>
              <li>
                <button
                  onClick={() => setActiveTab('fromExistingObjects')}
                  className={`inline-block px-6 py-3 border-b-2 rounded-t-lg ${
                    activeTab === 'fromExistingObjects'
                      ? 'text-blue-600 border-gray-400 border-b-blue-600 rounded-t-lg border'
                      : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                  }`}
                >
                  {t('batch-creation-existing-text')}
                </button>
              </li>
            </ul>
          </div>
        )}

        {activeTab === 'fromNewObjects' && !editMode && (
          <div className="border-2 border-gray-400 rounded-md p-6 mb-6 bg-white shadow-xl h-80 ">
            <FormField
              value={values.numberOfNewAssets}
              name={'numberOfNewAssets'}
              placeholder={t('batch-number-holder')}
              onChange={handleChange}
              type="number"
              isError={!!errors.numberOfNewAssets && !!touched.numberOfNewAssets}
              errorMessage={(touched.numberOfNewAssets && errors?.numberOfNewAssets) || ''}
            />
          </div>
        )}

        {activeTab === 'fromExistingObjects' && (
          <div className="border-2 border-gray-400 rounded-md p-6 mb-6 bg-white shadow-xl h-80">

            <BatchTagsMultiselect
              setFieldValue={setFieldValue}
              placeholder={t('select-tags-placeholder')}
              onChange={(tags) => setSelectedTags(tags)}
            />

            <FormField
              value={values.consecutiveAssetsCount}
              name={'consecutiveAssetsCount'}
              placeholder={t('consecutive-assets-placeholder')}
              onChange={handleChange}
              type="number"
              isError={!!errors.consecutiveAssetsCount && !!touched.consecutiveAssetsCount}
              errorMessage={(touched.consecutiveAssetsCount && errors?.consecutiveAssetsCount) || ''}
              className={`w-1/4 ${
                selectedTags.length !== 1 || values.numberOfNewAssets === undefined || values.numberOfNewAssets === 0
                  ? 'bg-gray-200'
                  : 'bg-white'
              }`}
            />
          
            <BatchTypeSelect
              type={values.type}
              setFieldValue={setFieldValue}
              isError={!!errors.type && !!touched.type}
              errorMessage={(touched.type && errors?.type) || ''}
            />
          </div>
        )}

        {editMode === 'editBatchAssets' && (
          <>
            <div data-color-mode="light" className="markdown-editor mb-8">
              <CustomMDEditor
                id="assets-description-editor"
                label={t('assets-description-place-holder')}
                value={values.assetsDescription || ''}
                onChange={(value) => setFieldValue('assetsDescription', value)}
              />
            </div>
            {touched.description && errors.description && (
              <div className="error-message">{errors.description}</div>
            )}
            <TagStateSelect
              state={values.assetState}
              setFieldValue={setFieldValue}
              field="assetState"
            />
            <LocationSelect location={values.location} setFieldValue={setFieldValue} />
            <CategoriesSelect
              categories={values.categories || []}
              setFieldValue={setFieldValue}
            />
          </>
        )}

        <OrganizationsSelect
          setFieldValue={setFieldValue}
          value={values.organization}
          isError={!!errors.organization && !!touched.organization}
          errorMessage={(touched.organization && errors?.organization) || ''}
          isClearable
          placeholder={t('select-organization-placeholder')}
        />

        <div className="flex justify-center mt-4 pb-10 mb-10">
          <SaveButton submitFunc={submitForm} disabled={isLoading} />
          <CloseButton onClose={onClose} disabled={isLoading} />
        </div>

        {editMode === 'editBatchAssets' && (
          <>
            {assetId && (
          <CustomAccordion
            isAccordionOpen={isUploadAccordionOpen}
            setIsAccordionOpen={setIsUploadAccordionOpen}
            accordionWrapperClassName="my-10"
            contentWrapperClassName="flex items-center justify-center"
            title={t('edit-upload-label', { ns: 'tag' })}
          >
            <TakePhotoButton
              assetId={data?.id ?? 0}
              onImageCapture={(img) => {}}
              buttonText={t('context-menu-take-photo-label', { ns: 'tag' })}
              className="m-5"
              isBatchMode={true}
            />

            <UploadDocumentsButton
              onUpload={async (formData) => {
                try {
                  await postBatchDocument({
                    batchId: data?.id,
                    body: formData,
                  }).unwrap();
                } catch (error) {
                  console.error('Error during upload:', error);
                }
              }}
              buttonText={t('context-menu-upload-file-label', { ns: 'tag' })}
              className="additional-custom-styles"
            />
          </CustomAccordion>
        )}
          </>
        )}

        <ServerFormError
          title={t('error-request')}
          requestError={error as ServerError}
          isError={isError}
        />
      </FormWrapper>
    </ModalContainer>
  );
};

export default React.memo(BatchForm);
