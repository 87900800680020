import React, { useRef, useCallback } from 'react';
import { ReactComponent as IconUpload } from 'src/assets/IconUpload.svg';
import ButtonWithIcon from 'src/components/ButtonWithIcon';

interface UploadDocumentsButtonProps {
  onUpload: (formData: FormData) => Promise<void>;
  buttonText: string;
  className?: string;
}

const UploadDocumentButton: React.FC<UploadDocumentsButtonProps> = ({
  onUpload,
  buttonText,
  className,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (file) {
        const maxSize = 5 * 1024 * 1024;

        if (file.size > maxSize) {
          console.error('File too large');
          return;
        }

        try {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('fileName', file.name);
          formData.append('isPublic', 'true');
          formData.append('documentType', 'DOCUMENT');

          await onUpload(formData);
        } catch (error) {
          console.error('File upload failed');
        }
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
    [onUpload]
  );

  return (
    <>
      <ButtonWithIcon
        className={`flex items-center justify-center border-green-700 text-green-700 gap-1 py-[10px] px-5 m-5 h-[52px] rounded text-lg leading-normal text-center border-2 border-solid transition-colors duration-300 ease-linear ${className || ''}`}
        icon={<IconUpload className="w-5 h-5 mr-2 fill-green-700" />}
        text={buttonText}
        onClick={handleClick}
      />
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        onChange={handleFileChange}
      />
    </>
  );
};

export default UploadDocumentButton;
