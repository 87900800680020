import { ReactNode, useCallback } from 'react';
import { Button, Modal } from 'flowbite-react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { FlowbiteColors } from 'flowbite-react/lib/esm/components/Flowbite';
import './styles.module.css';

interface IConfirmationModal {
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
  onClose: () => void;
  confirmationText: string;
  confirmationAcceptText: string;
  confirmationCancelText: string;
  confirmationCancelColor?: keyof typeof FlowbiteColors; 
  confirmationAcceptColor?: keyof typeof FlowbiteColors; 
  children?: ReactNode;
}

const ConfirmationModal = ({
  isOpen,
  onAccept,
  onCancel,
  onClose,
  confirmationText,
  confirmationCancelText,
  confirmationAcceptText,
  confirmationCancelColor = 'gray', 
  confirmationAcceptColor = 'failure', 
  children,
}: IConfirmationModal) => {
  const handleConfirmationModalClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleConfirmationAccept = useCallback(() => {
    onAccept();
  }, [onAccept]);


  const handleConfirmationCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <Modal
      show={isOpen}
      size="md"
      onClose={handleConfirmationModalClose}
      popup
      data-label="confirmation-modal"
    >
      <Modal.Header />
      <Modal.Body className="flex justify-center items-center">
        <div className="min-w-[25vw] !h-[30vh] bg-white rounded-lg p-2">
          <div className="flex justify-center items-center flex-col text-center w-full h-full">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {confirmationText}
            </h3>
            {children}
            <div className="flex justify-center gap-4">
              <Button color={confirmationAcceptColor} onClick={handleConfirmationAccept}>
                {confirmationAcceptText}
              </Button>
              <Button color={confirmationCancelColor} onClick={handleConfirmationCancel}>
                {confirmationCancelText}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
