import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const TagValidation = () => {
  const { t } = useTranslation('tag');
  return yup.object().shape({
    name: yup.string().required(t('edit_tag_name_error_message')),
    description: yup.string(),
    state: yup.string(),
    organization: yup.string().required(t('required-asset-organization')),
  });
};
