import React, { useState, useCallback } from 'react';
import { ReactComponent as IconCamera } from 'src/assets/footer-menu/IconCamera.svg';
import ModalContainer from 'src/components/Modals/components/ModalContainer';
import ImageScanner from 'src/components/ImageScanner';
import ButtonWithIcon from 'src/components/ButtonWithIcon';

interface TakePhotoButtonProps {
  assetId: number;
  onImageCapture?: (image: string) => void;
  buttonText?: string;
  className?: string;
  isBatchMode?: boolean;
}

const TakePhotoButton: React.FC<TakePhotoButtonProps> = ({
  assetId,
  onImageCapture,
  buttonText = 'Take Photo',
  className = '',
  isBatchMode = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const handleImageCapture = useCallback(
    (image: string) => {
      onImageCapture?.(image);
      closeModal();
    },
    [onImageCapture, closeModal]
  );

  return (
    <>
      <ButtonWithIcon
        className={`flex items-center justify-center border-green-700 text-green-700 gap-1 py-[10px] px-5 m-5 h-[52px] rounded text-lg leading-normal text-center border-2 border-solid transition-colors duration-300 ease-linear ${className}`}
        icon={<IconCamera className="w-5 h-5 mr-2 fill-green-700" />}
        text={buttonText}
        onClick={openModal}
      />
      <ModalContainer isModalOpened={isModalOpen} onClose={closeModal}>
        <ImageScanner
          onClose={closeModal}
          addLocalImage={handleImageCapture}
          assetId={assetId}
          isBatchMode={isBatchMode}
        />
      </ModalContainer>
    </>
  );
};

export default TakePhotoButton;
